import React from 'react'
import {NavLink} from 'react-router-dom';
import { Link } from "react-router-dom";

const Menues = () => {

  return (
    <>
    <div class="navigation-bar-container">
      <div class="nav-container">
        <div class="logo-container">
          <img src="assets/images/logo/logo.png" alt="Maharishi University Online" />
        </div>
        <div class="navbar">
          <div class="nav-mobile">
            <a href="#" id="nav-toggle"><span></span></a>
          </div>
          <ul class="nav-list">
            <li><Link to="/">Home</Link></li>
            <li><Link to="About">About</Link></li>
            <li><Link to="Contactus">Contact</Link></li>

            <li><a href="#">Animation <i class="fa fa-caret-down"></i></a>
              <ul class="nav-dropdown">
              <li><Link to="fundamental-of-3D-animation">Fundamentals of 3D Animation</Link></li>
          <li><Link to="visual-development-concept-art-advanced">Visual Development / Concept Art Advanced</Link></li>
          <li><Link to="motion-design-for-graphic-designers"> Motion design for graphic designers</Link></li>
          <li><Link to="Architecture-visualization">Architecture Visualization</Link></li>
                      </ul>
                    
                </li>
                <li>
                  <a href="#">Marketing <i class="fa fa-caret-down"></i></a>
              <ul class="nav-dropdown">
              <li><Link to="advance-digital-marketing-course">Advanced Certification in Digital</Link></li>
          <li><Link to="digital-marketing-course"> Fundamentals of Digital Marketing</Link></li>
          <li><Link to="Social-Media-Marketing">Social Media Marketing Short-Term Course</Link></li>
                      </ul>
                      </li>
                
                <li>
                  <a href="#">Entrepreneurship <i class="fa fa-caret-down"></i></a>
              <ul class="nav-dropdown">
              <li><Link to="Entrepreneurship">Entrepreneurship</Link></li>
                      </ul>
                    
                </li>
                <li>
                  <a href="#">Yoga/Health <i class="fa fa-caret-down"></i></a>
              <ul class="nav-dropdown">
              <li><Link to="maharishi-diet-and-nutrition">Diet & Nutrition Course</Link></li>
          <li><Link to="certification-in-maharishi-yoga">Certification in Yoga</Link></li>
                      </ul>
                      </li>
                <a target='_blank' className='rbt-btn rbt-switch-btn btn-gradient btn-sm' href="https://edu.muitonline.com/login/index.php">Login</a>  &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;

              <a target='_blank' className='rbt-btn rbt-switch-btn btn-gradient btn-sm'  href="#https://registration.muitonline.com/registration-form">Register</a>  


          </ul>
         
        </div>
      </div>
    </div>  


    </>
  )
}

export default Menues